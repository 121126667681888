<template>
  <!-- 单日期（时间）框 -->
  <el-date-picker
    class="date_picker"
    v-model="svalue"
    :picker-options="pickerOptions"
    value-format="yyyy-MM-dd HH:mm:ss"
    type="datetime"
    placeholder="选择日期时间"/>
</template>
<script>
export default {
  name: 'DateTimeSimple1',
  props: {
    value: {
      type: String,
      default: ''
    },
    // 设置时间范围(可选今日以后时间或者今日之前时间配置)
    pickerOptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      svalue: ''
    }
  },
  watch: {
    // 判断下拉框的值是否有改变
    svalue(val, oldVal) {
      if (!val) {
        val = ''
      }
      this.$emit('input', val)
    },
    value(val, oldval) {
      this.svalue = val
    }
  },
  mounted() {
    // 初始化下拉框的值
    this.svalue = this.value
  },
  methods: {
  }
}
</script>
<style scoped>
</style>
