const routes = [
    {
        path: '/',
        component: resolve => require(["@/views/Home.vue"], resolve),
        redirect: '/index',
        children: [
            {
                path: "/index",
                name: "index",
                component: resolve => require(["@/views/pages/system/index.vue"], resolve),
            }
        ]
    },
    {
        path: '/login',
        component: resolve => require(["@/views/login.vue"], resolve),
    }
]
export default routes