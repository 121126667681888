<template>
    <!-- 商品商户 -->
    <el-select v-model="svalue" :placeholder="users" :multiple="multiple" :disabled="disabled" :clearable="true" filterable collapse-tags>
      <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"/>
    </el-select>
  </template>
  <script>
  // import store from '@/store'
  import { getStoreList } from '@/api/product'
  export default {
    name: 'RoleDrop',
    props: {
      users: {
        type: String,
        default: '请选择商品商户'
      },
      multiple: {
        type: Boolean,
        default: true
      },
      value: {
        type: [Number, Array, String],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        svalue: [],
        params: {
            name: '',
            state:0,
            page: 1,
            rows: 100000
        },
        options: []
      }
    },
    watch: {
      // 判断下拉框的值是否有改变
      svalue(val, oldVal) {
        if (val !== oldVal) {
          this.$emit('input', this.svalue)
        }
      },
      value(val, oldval) {
        this.svalue = val
      }
    },
    created() {
      this.loadData()
    },
    mounted() {
      // 初始化下拉框的值
      this.svalue = this.value
    },
    methods: {
      loadData() {
        getStoreList(this.params).then(response => {
          this.options = response.data.list
        })
      }
    }
  }
  </script>
  