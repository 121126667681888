import request from '@/utils/request'

export function login(data) {
  data.moduleId = process.env.MODULE_ID
  return request({
    url: '/v1/vsa/user/login/web',
    method: 'post',
    data: data
  })
}
// 获取目录
export function getMenu(data) {
  return request({
    url: '/v1/admin/user/menu',
    method: 'get',
    data: data
  })
}

// oss配置接口
export function getOss() {
  return request({
    url: '/v1/admin/oss',
    method: 'get'
  })
}


