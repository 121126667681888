import Vue from 'vue'
import VueRouter from 'vue-router';
import { getToken } from '@/utils/auth'
Vue.use(VueRouter)
import routes from "./routes";
const router = new VueRouter({ 
    mode:"history",
    routes: routes 
});
// 重写路由push方法
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return routerPush.call(this, location, onResolve, onReject);
  return routerPush.call(this, location).catch(error => error)
};
// 设置路由拦截
// router.beforeEach((to, from, next) => {
//   let isLogin = getToken();
//   if (isLogin) {
//     //如果用户信息存在则往下执行。
//     next()
//   } else {
//     //如果用户token不存在则跳转到login页面
//     if (to.path === '/login') {
//       next()
//     } else {
//       next('/login')
//     }
//   }
// })
export default router