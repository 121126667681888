// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from "@/router/index.js" //引入路由器
Vue.config.productionTip = false
import "./common/css/reset.css"; //引入重置样式表
import ElementUI from 'element-ui'; //引入饿了么包
import 'element-ui/lib/theme-chalk/index.css';//引入饿了么样式
Vue.use(ElementUI);
import VueQuillEditor from 'vue-quill-editor'
import '../src/assets/css/core.css'
import '../src/assets/css/snow.css'
import '../src/assets/css/bubble.css'

Vue.use(VueQuillEditor)

// 把vuex仓库引入并挂载在Vue实例上
import store from './store/index'
// 导入msg提示框
import Dialog from '@/views/components/dialog/dialog.vue'
Vue.use(Dialog)
// 导入公共样式
import '@/assets/css/public.css'
import usersDrop from '@/views/components/form/UserDrop' // 系统用户下拉
import roleDrop from '@/views/components/form/RoleDrop' // 角色下拉
import receptionUserDrop from '@/views/components/form/receptionUserDrop' // 前台用户下拉
import DateTimeDrop from '@/views/components/form/DateTimeDrop' // 开始-结束 时间选择器
import videoTypeDrop from '@/views/components/form/videoTypeDrop' // 视频类型
import classifyDrop from '@/views/components/form/classifyDrop' // 资讯分类
import DateTimeSimple from '@/views/components/form/DateTimeSimple' // 单日期选择
import companyDrop from '@/views/components/form/companyDrop' // 快递公司
import AssetTypeDrop from '@/views/components/form/AssetTypeDrop' // 资产类型
import billTypeDrop from '@/views/components/form/billTypeDrop' // 账单类型
import DateTimeSimple1 from '@/views/components/form/DateTimeSimple1' // 日期时间
import storeDrop from '@/views/components/form/storeDrop' // 商品商户


Vue.component('usersDrop', usersDrop)
Vue.component('roleDrop', roleDrop)
Vue.component('receptionUserDrop', receptionUserDrop)
Vue.component('DateTimeDrop', DateTimeDrop)
Vue.component('videoTypeDrop', videoTypeDrop)
Vue.component('classifyDrop', classifyDrop)
Vue.component('DateTimeSimple', DateTimeSimple)
Vue.component('companyDrop', companyDrop)
Vue.component('AssetTypeDrop', AssetTypeDrop)
Vue.component('billTypeDrop', billTypeDrop)
Vue.component('DateTimeSimple1', DateTimeSimple1)
Vue.component('storeDrop', storeDrop)


import echarts from 'echarts'
Vue.prototype.$echarts = echarts


let vvvue = new Vue({
  render: h => h(App),
  router,
  store // 挂载上去
}).$mount('#app')

// console.log('vue实例对象',vvvue);