import request from '@/utils/request'

// 获取短剧列表
export function getList(params) {
    return request({
        url: '/v1/admin/manager/play/list?page=' + params.page + '&rows=' + params.rows + '&state=' + params.state + '&type=' + params.type + '&name=' + params.name,
        method: 'get'
    })
}

// 创建
export function createPlay(params) {
    return request({
        url: '/v1/admin/manager/play/create',
        method: 'post',
        data: params
    })
}

// 创建剧集
export function createPlayVideo(params) {
    return request({
        url: '/v1/admin/manager/play/video/create',
        method: 'post',
        data: params
    })
}


// 操作
export function operatePlay(params) {
    return request({
        url: '/v1/admin/manager/play/operate',
        method: 'post',
        data: params
    })
}

// 详情
export function getInfo(id) {
    return request({
        url: '/v1/admin/manager/play/info?id=' + id,
        method: 'get'
    })
}

// 视频类型
export function getVideoType() {
    return request({
        url: '/v1/admin/manager/play/type/list',
        method: 'get'
    })
}

// 上传视频
export function uploadVideo(params) {
    return request({
        url: '/v1/admin/upload',
        method: 'post',
        data: params
    })
}

// 剧集操作
export function playVideoOperate(params) {
    return request({
        url: '/v1/admin/manager/play/video/operate',
        method: 'post',
        data: params
    })
}
