import { login } from '@/api/auth'
import { getToken, setToken, removeToken, setRedirect, removeRedirect, getTimeTag, setTimeTag, removeTimeTag, getTimeSpan, setTimeSpan, removeTimeSpan, setNeedValid, removeNeedValid } from '@/utils/auth'
import router from '@/router'
import { Decrypt, getTimeDiff } from '@/utils/common'

function filterMenus(routes, serverMenus) {
  var validRoutes = routes.filter(router => {
    for (var index in serverMenus) {
      var serverMenu = serverMenus[index]
      if (serverMenu.url && serverMenu.type === 1 && router.menu && router.menu.toLowerCase() === serverMenu.url.toLowerCase()) {
        return true
      }

      if (router.menu && router.children && router.children.length > 0) {
        router.children = filterMenus(router.children, serverMenus)
        if (router.children && router.children.length > 0) {
          return true
        }
      }
    }
    return false
  })
  return validRoutes
}
function getmenus(serverMenus) {
  const menus = []
  for (var index in serverMenus) {
    menus.push(serverMenus[index])
    if (serverMenus[index].list && serverMenus[index].list.length > 0) {
      menus.push.apply(menus, getmenus(serverMenus[index].list))
    }
  }
  return menus
}
const user = {
  state: {
    token: getToken(),
    timeTag: getTimeTag(),
    timespan: getTimeSpan(),
    userid: 0,
    seareceived: 0,
    name: '',
    orgname: '',
    schoolname: '默认校区',
    avatar: '',
    calldata: {},
    roles: [],
    menulist: [],
    modulelist: [],
    permslist: [],
    routes: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REDIRECT: (state, redirect) => {
      state.redirect = redirect
    },
    SET_CALLDATA: (state, calldata) => {
      state.calldata = calldata
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_USERID: (state, userid) => {
      state.userid = userid
    },
    SET_ORGNAME: (state, name) => {
      state.orgname = name
    },
    SET_SEARECEIVED: (state, num) => {
      state.seareceived = num
    },
    SET_SCHOOLNAME: (state, name) => {
      state.schoolname = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MODULES: (state, modules) => {
      state.modulelist = modules
    },
    SET_ROUTES: (state, menus) => {
      state.menulist = menus
      // const allmenu = getmenus(menus)
      // state.routes = filterMenus(asyncRouterMap, allmenu)
      // router.addRoutes(state.routes)
    },
    SET_PERMS: (state, perms) => {
      state.permslist = perms
    }
  },

  actions: {
    // 登录
    Login({ commit }, logininfo) {
      return new Promise((resolve, reject) => {
        login(logininfo).then(response => {
          const data = response.data
          const redirect = 'recharge'
          setToken(data)
          // setNeedValid(response.data.needValid)
          commit('SET_TOKEN', data)
          // const timeTag = response.data.timetag
          // setTimeTag(timeTag)
          // setTimeSpan(getTimeDiff(response.timestamp))
          // commit('SET_REDIRECT', redirect)
          setRedirect(redirect)
          // 获取菜单
          // setTimeout(ress => {
          //   getMenu({}).then(res => {
          //     commit('SET_ROUTES', res.data.data)
          //   })
          // }, 4000)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.token) {
          return
        }
        getInfo(state.token).then(response => {
          const data = response.data
          const calldata = JSON.parse(Decrypt(data.callData))
          if (data.roleIdList && data.roleIdList.length > 0) {
            commit('SET_ROLES', data.roleIdList)
            commit('SET_ROUTES', data.menuList)
            commit('SET_MODULES', data.moduleList)
            commit('SET_PERMS', data.permsList)
          } else {
            reject('您还没有任何使用权限，请联系管理员授权!')
          }
          commit('SET_CALLDATA', calldata)
          commit('SET_USERID', data.userId)
          commit('SET_NAME', data.realName)
          commit('SET_ORGNAME', data.orgName)
          commit('SET_SCHOOLNAME', data.schoolName)
          commit('SET_AVATAR', data.chatIconUrl)
          commit('SET_SEARECEIVED', data.seaReceivedNum)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_REDIRECT', '')
          removeToken()
          removeTimeTag()
          // removeTimeSpan()
          removeNeedValid()
          removeRedirect()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_REDIRECT', '')
        removeToken()
        removeTimeTag()
        // removeTimeSpan()
        removeNeedValid()
        removeRedirect()
        resolve()
      })
    }
  }
}

export default user
