import request from '@/utils/request'

// 商品列表
export function getList(params) {
    return request({
      url: '/v1/admin/manager/product/list?productId=' + params.productId + '&status=' + params.status + '&name=' + params.name + '&page=' + params.page + '&rows=' + params.rows,
      method: 'get',
      data: params
    })
}

// 商品操作
export function doOperate(params) {
    return request({
      url: '/v1/admin/manager/product/operate',
      method: 'post',
      data: params
    })
}

// 商品详情
export function getInfo(productId) {
    return request({
      url: '/v1/admin/manager/product/info?productId=' + productId,
      method: 'get'
    })
}

// 创建sku
export function setupSku(params) {
    return request({
      url: '/v1/admin/manager/product/sku',
      method: 'post',
      data: params
    })
}

// 商品创建编辑
export function doManager(params) {
    return request({
      url: '/v1/admin/manager/product',
      method: 'post',
      data: params
    })
}

// 商品商户列表
export function getStoreList(params) {
  return request({
    url: '/v1/admin/manager/store/list?name=' + params.name + '&state=' + params.state + '&page=' + params.page + '&rows=' + params.rows,
    method: 'get'
  })
}

// 商品商户操作
export function storeOpt(params) {
  return request({
    url: '/v1/admin/manager/store/opt',
    method: 'post',
    data: params
  })
}
