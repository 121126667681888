import request from '@/utils/request'

// 账单明细
export function getList(params) {
    return request({
        url: '/v1/admin/manager/bill/list?page=' + params.page + '&rows=' + params.rows + '&operate=' + params.operate + '&assetType=' + params.assetType + '&type=' + params.type + '&keyword=' + params.keyword + '&account=' + params.account + '&start=' + params.start + '&end=' + params.end,
        method: 'get'
    })
}

// 账单类型
export function getBillType() {
    return request({
        url: '/v1/admin/manager/bill/type',
        method: 'get'
    })
}

// 账单资产类型
export function getBillAssetType() {
    return request({
        url: '/v1/admin/manager/bill/asset/type',
        method: 'get'
    })
}
