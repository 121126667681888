import request from '@/utils/request'

// 获取用户列表
export function getList(params) {
  return request({
    url: '/v1/admin/manager/user/list?account=' + params.account + '&inviter=' + params.inviter + '&state=' + params.state + '&startCreateTime=' + params.startCreateTime + '&endCreateTime=' + params.endCreateTime + '&startLoginTime=' + params.startLoginTime + '&endLoginTime=' + params.endLoginTime + '&page=' + params.page + '&rows=' + params.rows + '&level=' + params.level + '&updateLevel=' + params.updateLevel + '&showLevel=' + params.showLevel + '&type=' + params.type,
    method: 'get',
    data: params
  })
}

// 用户操作
export function operateUser(params) {
  return request({
    url: '/v1/admin/manager/user/operate',
    method: 'post',
    data: params
  })
}

// 用户详情
export function getUserInfo(params) {
  return request({
    url: '/v1/admin/manager/user/info?id=' + params.id,
    method: 'get'
  })
}

// 用户结构
export function getUserTree(params) {
  return request({
    url: '/v1/admin/manager/user/tree/list?userId=' + params.userId + '&page=' + params.page + '&rows=' + params.rows,
    method: 'get'
  })
}

// 用户统计
export function getSearch(params) {
  return request({
    url: '/v1/admin/manager/user/search/list?account=' + params.account + '&type=' + params.type + '&page=' + params.page + '&rows=' + params.rows,
    method: 'get'
  })
}
