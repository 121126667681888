import request from '@/utils/request'

// 获取资讯列表
export function getList(params) {
    return request({
        url: '/v1/admin/manager/news/list?keyword=' + params.keyword + '&type=' + params.type + '&state=' + params.state + '&cid=' + params.cid + '&page=' + params.page + '&rows=' + params.rows,
        method: 'get'
    })
}

// 分类列表
export function getTypeList() {
    return request({
        url: '/v1/admin/manager/news/classify/list',
        method: 'get'
    })
}

// 详情
export function getInfo(id) {
    return request({
        url: '/v1/admin/manager/news/info?id=' + id,
        method: 'get'
    })
}

// 分类操作
export function operateType(params) {
    return request({
        url: '/v1/admin/manager/news/classify/operate',
        method: 'post',
        data: params
    })
}

// 新增资讯
export function createList(params) {
    return request({
        url: '/v1/admin/manager/news/create',
        method: 'post',
        data: params
    })
}

// 操作资讯
export function operateList(params) {
    return request({
        url: '/v1/admin/manager/news/operate',
        method: 'post',
        data: params
    })
}
