<template>
  <!-- 系统角色 -->
  <el-select v-model="svalue" :placeholder="users" :multiple="multiple" :disabled="disabled" :clearable="true" filterable collapse-tags>
    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"/>
  </el-select>
</template>
<script>
// import store from '@/store'
import { getTypeList } from '@/api/listInfo'
export default {
  name: 'classifyDrop',
  props: {
    users: {
      type: String,
      default: '请选择分类'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Number, Array, String],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svalue: [],
      params: {
        type: 0
      },
      options: []
    }
  },
  watch: {
    // 判断下拉框的值是否有改变
    svalue(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('input', this.svalue)
      }
    },
    value(val, oldval) {
      this.svalue = val
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    // 初始化下拉框的值
    this.svalue = this.value
  },
  methods: {
    loadData() {
      getTypeList().then(response => {
        this.options = response.data
      })
    }
  }
}
</script>
