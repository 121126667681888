import request from '@/utils/request'

// 获取用户列表
export function getUserList(params) {
  return request({
    url: '/v1/admin/user/list?type=' + params.type,
    method: 'get',
    data: params
  })
}

// 新增用户
export function addCreateUser(params) {
  return request({
    url: '/v1/admin/user/create',
    method: 'post',
    data: params
  })
}

// 解冻  冻结 删除 用户
export function doChangeUser(params) {
  return request({
    url: '/v1/admin/user/delete',
    method: 'post',
    data: params
  })
}

// 修改密码
export function doChangePassword(data) {
  return request({
    url: '/v1/admin/user/password?password=' + data,
    method: 'get'
  })
}

// 角色列表
export function getRoleList(params) {
  return request({
    url: '/v1/admin/role/list',
    method: 'get',
    data: params
  })
}

// 创建角色
export function addCreateRole(params) {
  return request({
    url: '/v1/admin/role/create',
    method: 'post',
    data: params
  })
}

// 角色绑定用户
export function bindRoleUser(params) {
  return request({
    url: '/v1/admin/role/bind',
    method: 'post',
    data: params
  })
}

// 角色删除
export function deleteRole(params) {
  return request({
    url: '/v1/admin/role/delete?roleId=' + params.roleId,
    method: 'get'
  })
}

// 获取权限
export function getPermissions(params) {
  return request({
    url: '/v1/admin/user/permissions',
    method: 'get',
    data: params
  })
}
