<template>
  <!-- 前台用户 -->
  <el-select v-model="svalue" :placeholder="users" :multiple="multiple" :disabled="disabled" :clearable="true" filterable collapse-tags>
    <el-option v-for="item in options" :key="item.id" :label="item.nickName" :value="item.id"/>
  </el-select>
</template>
<script>
// import store from '@/store'
import { getList } from '@/api/user'
export default {
  name: 'usersDrop',
  props: {
    users: {
      type: String,
      default: '请选择用户'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Number, Array, String],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svalue: [],
      params: {
        userId: '',
        account: '', // 手机号或者邀请码
        inviter: '', // 邀请人手机号码或邀请码搜索
        state: 0,
        startCreateTime: '',
        endCreateTime: '',
        startLoginTime: '',
        endLoginTime: '',
        level: 0,
        updateLevel: 0,
        showLevel: 0,
        type: 0,
        page: 1,
        rows: 20
      },
      options: []
    }
  },
  watch: {
    // 判断下拉框的值是否有改变
    svalue(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('input', this.svalue)
      }
    },
    value(val, oldval) {
      this.svalue = val
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    // 初始化下拉框的值
    this.svalue = this.value
  },
  methods: {
    loadData() {
      getList(this.params).then(response => {
        this.options = response.data.list
      })
    }
  }
}
</script>
