import request from '@/utils/request'

/*
 *商品订单 
*/

// 商品订单列表
export function getList(params) {
    return request({
        url: '/v1/admin/manager/order/list?orderNo=' + params.orderNo + '&expressType=' + params.expressType + '&status=' + params.status + '&minAmount=' + params.minAmount + '&maxAmount=' + params.maxAmount + '&startPayTime=' + params.startPayTime + '&endPayTime=' + params.endPayTime + '&startCompleteTime=' + params.startCompleteTime + '&endCompleteTime=' + params.endCompleteTime + '&mobile=' + params.mobile + '&page=' + params.page + '&rows=' + params.rows,
        method: 'get'
    })
}

// 订单详情
export function getOrderInfo(params) {
    return request({
        url: '/v1/admin/manager/order/info?id=' + params.id,
        method: 'get'
    })
}


// 发货
export function orderExpress(params) {
    return request({
        url: '/v1/admin/manager/order/express',
        method: 'post',
        data: params
    })
}

// 操作
export function orderOperate(params) {
    return request({
        url: '/v1/admin/manager/order/operate',
        method: 'post',
        data: params
    })
}

// 订单导出
export function orderExport(params) {
    return request({
        url: '/v1/admin/manager/order/export?orderNo=' + params.orderNo + '&expressType=' + params.expressType + '&status=' + params.status + '&minAmount=' + params.minAmount + '&maxAmount=' + params.maxAmount + '&startPayTime=' + params.startPayTime + '&endPayTime=' + params.endPayTime + '&startCompleteTime=' + params.startCompleteTime + '&endCompleteTime=' + params.endCompleteTime + '&mobile=' + params.mobile,
        method: 'get',
        responseType: 'blob'
    })
}

// 快递公司
export function orderCompany() {
    return request({
        url: '/v1/admin/manager/order/company',
        method: 'get'
    })
}

/*
 *商品订单end
*/


/*
* 话剧订单
*/

// 列表
export function getHList(params) {
    return request({
        url: '/v1/admin/manager/play/order/list?state=' + params.state + '&timeType=' + params.timeType + '&start=' + params.start + '&end=' + params.end + '&keyword=' + params.keyword + '&number=' + params.number + '&payWay=' + params.payWay + '&account=' + params.account + '&page=' + params.page + '&rows=' + params.rows,
        method: 'get'
    })
}

/*
* 话剧订单end
*/

/*
* 提交剧本
*/

// 剧本列表
export function getBookList(params) {
    return request({
        url: '/v1/admin/manager/play/book/list?state=' + params.state + '&account=' + params.account + '&page=' + params.page + '&rows=' + params.rows,
        method: 'get'
    })
}

// 通过剧本
export function passBook(params) {
    return request({
        url: '/v1/admin/manager/play/book/pass',
        method: 'post',
        data: params
    })
}

// 拒绝剧本
export function rejectBook(params) {
    return request({
        url: '/v1/admin/manager/play/book/reject',
        method: 'post',
        data: params
    })
}

/*
* 提交剧本end
*/
