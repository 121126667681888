<template>
  <!-- 日期（日期）框 -->
  <el-date-picker
    v-model="svalue"
    :start-placeholder="start"
    :end-placeholder="end"
    :clearable="clearable"
    :default-time="['00:00:00', '23:59:59']"
    value-format="yyyy-MM-dd HH:mm:ss"
    type="daterange"
    range-separator="~"
  />
</template>
<script>
export default {
  name: "DateTimeDrop",
  props: {
    value: {
      type: Array,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    start: {
      type: String,
      default: "开始日期",
    },
    end: {
      type: String,
      default: "结束日期",
    }
  },
  data() {
    return {
      svalue: ["", ""]
    };
  },
  watch: {
    svalue(val, oldval) {
      if (!val) {
        val = ["", ""];
      }
      this.$emit("input", val);
    },
    value(val, oldval) {
      this.svalue = val;
    },
  },
  mounted() {
    // 初始化下拉框的值
    this.svalue = this.value;
  },
  methods: {},
};
</script>
<style scoped>
</style>
